/*  body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body{
  background-color: #1a1f2e;
}

.spinner-border {
  border: 5px solid rgba(255, 255, 255, 0.422);
  border-top: 5px solid #9b59b6; /* Purple */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1.5s linear infinite;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.css-1g5k5ll{
  padding: 6px 10px !important;
}

.connect-wallet{
  text-align: center;
    margin: 10px;
    display: flex;
    justify-content: center;
    justify-items: center;
    gap: 1rem;
}